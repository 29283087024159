





































import type { BlogCard } from '@/inc/types'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { pushCta, GtmCta } from '@/inc/utils'

import UiPicture from '@/components/ui/Picture.vue'

export interface CtaExperience {
  experience: BlogCard
  trackingLabel: string
}

export default defineComponent({
  name: 'CtaExperience',
  components: { UiPicture },
  props: {
    content: {
      type: Object as PropType<CtaExperience>,
      required: true,
    },
  },
  setup(props) {
    const rootElRef = ref<HTMLElement | null>(null)

    // Push event to datalayer on click
    const onLinkClick = () => {
      if (!props.content.experience.link || !rootElRef.value) {
        return
      }

      const ctaLabel = (props.content.experience.link.label ||
        props.content.experience.link.title) as string

      const layer: Partial<GtmCta> = {
        ctaLabel,
        ctaType: 'experience',
        ctaUrl: props.content.experience.link.url,
      }

      pushCta(layer, rootElRef.value)
    }

    return {
      rootElRef,
      onLinkClick,
    }
  },
})
